/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/lightbox2@2.11.5/dist/js/lightbox.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
